import React, { useContext, useEffect, useMemo, useState } from "react";
import { WrappedTable, Status } from "@/shared/ui";
import { IModalContextValue, ModalContext } from "@/shared/lib/react";
import { useQueryClient } from "react-query";
import { useDebounce } from "use-debounce";
import {
  CallMessageOutput,
  GetCallsFilters,
  GetCallsOutputData,
  GetCallsPagination,
  GetCallsSortInfo,
  Query,
  useGetCallsQuery,
  useUpdateCallMutation,
  RoleEnum,
  useCallWebsocket,
} from "@/shared/lib/graphql";
import { useSearchParams } from "react-router-dom";
import { useAddNotification, useQueryFilters } from "@/shared/lib/react";
import { getCapitalized } from "@/shared/helpers";
import Cookies from "js-cookie";
import { PAGINATION_DEFAULT_OPTIONS } from "@/shared/helpers/const";
import { MockCallsFilters } from "@/shared/helpers/mocks";
import { useManagers } from "@/entities/user";
import { ModalCreateLeadFromCall } from "@/widgets/calls";
import { callsTableHeaders } from "../model/const";
import { ModalManagerAppointment } from "@/features/user";
import { CallActionButton, CallManager } from "@/features/calls";

export const CallsTable: React.FC = () => {
  const queryClient = useQueryClient();
  const { selectedFilters } = useQueryFilters();
  const me = queryClient.getQueryData<Pick<Query, "getMe">>("getMe", {
    predicate: (query) => query.queryHash === `getMe-${Cookies.get("accessToken")}`,
  }) as Pick<Query, "getMe">;
  const notify = useAddNotification();

  const [searchParams, setSearchParams] = useSearchParams();
  const [newCalls, setNewCalls] = useState<Array<{ [key: string]: any }>>([]);
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const [callUpdating, setCallUpdating] = useState<"self" | "other" | null>(null);
  const [editingCallId, setEditingCallId] = useState<number | undefined>();
  const [pagination, setPagination] = useState<GetCallsPagination>(PAGINATION_DEFAULT_OPTIONS);
  const [filter, setFilter] = useState<GetCallsFilters>({});
  const [sort, setSort] = useState<GetCallsSortInfo>({});
  const [searchDebouncedValue] = useDebounce(filter?.number?.replace("+", ""), 500);
  const { allManagers } = useManagers();
  const [selectedCall, setSelectedCall] = useState({
    callId: 0,
    phone: "",
    managerId: 0,
  });
  useEffect(() => {
    const page = searchParams.get("page");

    if (!page) return;

    handlePaginationClick(+page - 1);
  }, [setSearchParams]);

  const { data, isLoading, refetch } = useGetCallsQuery(
    {
      input: {
        filter: {
          ...selectedFilters.filter,
          number: searchDebouncedValue,
        },
        pagination,
        sort,
      },
    },
    {
      refetchInterval: 6000,
    }
  );

  const { mutate: updateCall, isLoading: isCallUpdating } = useUpdateCallMutation();
  const tablePagination = {
    page: Math.floor((data?.getCalls?.meta?.skip || 1) / (data?.getCalls?.meta?.take || 1)),
    count: Math.ceil((data?.getCalls?.meta?.count || 0) / (pagination.take || 1)),
  };

  useEffect(() => {
    if (!isCallUpdating) setCallUpdating(null);
  }, [isCallUpdating]);

  const userRole: any = me?.getMe.role?.slug;
  const canChangeManager = [RoleEnum.Director, RoleEnum.HeadOfMn].includes(userRole);

  const handleUpdateCall = (managerId: number, editingId?: number) => {
    if (!editingCallId && !editingId) return;

    updateCall(
      { id: (editingCallId || editingId) as number, input: { managerId } },
      {
        onSuccess: async () => {
          await refetch();
          setEditingCallId(undefined);
          handleCloseModal("modalManagerAppointment");
          notify("Менеджер назначен", "success");
        },
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  const handleAppointManager = (callId: number, isSelf: boolean = false) => {
    if (!isSelf) {
      setEditingCallId(callId);
      setCallUpdating("other");
      handleOpenModal("modalManagerAppointment");
    } else {
      setCallUpdating("self");
      handleUpdateCall(me?.getMe?.id, callId);
    }
  };

  const handleCallUpdate = (data: CallMessageOutput) => {
    setNewCalls((prev) => [prepareData(data as GetCallsOutputData), ...prev]);
    notify("Поступил новый звонок", "success");
  };

  useCallWebsocket({ onSuccess: handleCallUpdate });

  const handlePaginationClick = (value) => {
    setPagination((prevState) => ({
      ...prevState,
      skip: value * (data?.getCalls?.meta?.take || pagination.take || 10),
    }));
  };

  const handleOpenModal = (modalName: keyof IModalContextValue) => {
    setModalContext((prev) => ({ ...prev, [modalName]: true }));
  };

  const handleCloseModal = (modalName: keyof IModalContextValue) => {
    setModalContext((prev) => ({ ...prev, [modalName]: false }));
  };

  const handleSort = (headerItem, order) => {
    setSort({
      [headerItem.id]: order ? getCapitalized(order) : null,
    });
  };

  const handleCreateRequest = (call: GetCallsOutputData) => {
    setSelectedCall({
      phone: call.from || "",
      managerId: call?.manager?.id || 0,
      callId: call.id,
    });
    handleOpenModal("modalCreateRequest");
  };

  const prepareData = (item: GetCallsOutputData) => ({
    ...item,
    phone: <a href={`tel:${item.from}`}>{item.from}</a>,
    date: new Intl.DateTimeFormat('ru-RU', {
      dateStyle: 'short',
      timeStyle: 'short',
      timeZone: 'Europe/Moscow',
    }).format(new Date((new Date(item.createdAt).getTime()) + 1000 * 60 * 60 * 3)),
    status: <Status status={item.status} />,
    manager: (
      <CallManager
        call={item}
        canChangeManager={canChangeManager}
        onAppointManager={handleAppointManager}
        isLoading={item.id === editingCallId && callUpdating === "self"}
      />
    ),
    action: <CallActionButton call={item} onCreateRequestClick={handleCreateRequest} />,
  });

  const preparedTableData = useMemo(() => {
    setNewCalls([]);
    return data?.getCalls?.data?.map((item) => prepareData(item as GetCallsOutputData)) || [];
  }, [data, editingCallId]);

  return (
    <>
      <WrappedTable
        filters={MockCallsFilters(allManagers || [])}
        showMenu={false}
        tableTitle="Все звонки"
        headers={callsTableHeaders}
        data={[...newCalls, ...preparedTableData]}
        search={{
          placeholder: "Поиск по звонкам",
          onSearch: (value) => setFilter((prevState) => ({ ...prevState, number: value })),
        }}
        isLoading={isLoading}
        onSort={handleSort}
        onMenuItemSelected={() => ({})}
        pagination={tablePagination}
        onFiltersApply={(data) => setFilter((prevState) => ({ ...prevState, ...data }))}
        onPaginationClick={handlePaginationClick}
      />
      <ModalManagerAppointment
        title="Назначение менеджера на звонок"
        isLoading={isCallUpdating}
        onCancel={() => handleCloseModal("modalManagerAppointment")}
        onSubmit={handleUpdateCall}
      />
      {modalContext?.modalCreateRequest && (
        <ModalCreateLeadFromCall selectedCall={selectedCall} invalidateCalls={refetch} />
      )}
    </>
  );
};
